<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate"
        >新增赛制</el-button
      >
      <el-select
        v-model="listQuery.is_on"
        placeholder="状态"
        clearable
        style="width: 150px; margin-right: 50px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="已下架" :value="2" />
      </el-select>
      比赛时间：
      <el-date-picker
        v-model="dateArr"
        type="daterange"
        align="right"
        value-format="yyyy-MM-dd"
        unlink-panels
        range-separator="至"
        start-placeholder="比赛开始日期"
        end-placeholder="比赛结束日期"
        class="filter-item"
        style="display: inline-flex"
      ></el-date-picker>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="赛制名称" min-width="110" align="center">
        <template slot-scope="scope"> {{ scope.row.name }} </template>
      </el-table-column>
      <el-table-column label="开始时间" min-width="110" align="center">
        <template slot-scope="scope"> {{ scope.row.start_at }}</template>
      </el-table-column>
      <el-table-column label="结束时间" min-width="110" align="center">
        <template slot-scope="scope"> {{ scope.row.end_at }}</template>
      </el-table-column>
      <el-table-column label="比赛队伍" min-width="110" align="center">
        <template slot-scope="scope"> {{ scope.row.team_num }}</template>
      </el-table-column>
      <el-table-column label="排序" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.weight }} </template>
      </el-table-column>
      <!-- <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success">正常</el-tag>
          <el-tag type="danger">已下架</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleCheckDetails(scope.row)"
            >赛程安排</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleViewRanking(scope.row)"
            v-if="scope.row.type == 1"
            >查看排行</el-button
          >
          <!-- <el-button
            size="mini"
            type="primary"
            @click="handleMatchChart(scope.row)"
            >对阵图管理</el-button
          > -->
          <el-button
            size="mini"
            type="primary"
            @click="handleEditSort(scope.row)"
            >设置排序</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleEditMatch(scope.row)"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="赛制别称" prop="type">
          <el-select
            v-model="form.type"
            placeholder="赛制类型"
            clearable
            style="width: 150px; margin-right: 50px"
            class="filter-item"
          >
            <el-option label="积分赛" :value="1" />
            <el-option label="淘汰赛" :value="2" />
          </el-select>
        </el-form-item>

        <el-form-item label="赛制别称" prop="name">
          <el-input type="text" v-model="form.name" clearable />
        </el-form-item>
        <el-form-item
          label="胜者积分"
          prop="winner_points"
          v-if="form.type == 1"
        >
          <el-input type="text" v-model="form.winner_points" clearable />
        </el-form-item>
        <el-form-item
          label="败者积分"
          prop="loser_points"
          v-if="form.type == 1"
        >
          <el-input type="text" v-model="form.loser_points" clearable />
        </el-form-item>
        <el-form-item label="开始时间" prop="start_at">
          <el-date-picker
            v-model="form.start_at"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="结束时间" prop="end_at">
          <el-date-picker
            v-model="form.end_at"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">返回</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="赛事排序"
      :visible.sync="rankShow"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="比赛排序" prop="end_at">
          <el-input-number
            v-model="num"
            @change="handleChange"
            :min="1"
            :max="10"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="rankShow = false">返回</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">
          提交
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import uploadOne from "@/components/UploadOne/index.vue";
export default {
  components: {
    uploadOne,
  },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10,
        match_id: null,
        start_at: null,
        end_at: null,
      },
      form: {
        id: "",
        match_id: this.$route.query.id,
        type: "",
        name: "",
        winner_points: "",
        loser_points: "",
        start_at: "",
        end_at: "",
      },
      listLoading: false,
      list: [],
      total: null,
      dialogFormVisible: false,
      rankShow: false,
      dialogStatus: "",
      num: 0,
      btnLoading: false,
      dateArr: null,
      textMap: { create: "添加", update: "编辑" },
      rules: {
        id: [{ required: true, message: "不能为空", trigger: "change" }],
        type: [
          { required: true, message: "赛事类型不能为空", trigger: "change" },
        ],
        name: [
          { required: true, message: "赛事名称不能为空", trigger: "change" },
        ],
        winner_points: [
          { required: true, message: "胜者积分不能为空", trigger: "change" },
        ],
        loser_points: [
          { required: true, message: "败者积分不能为空", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_at = newVal[0];
        this.listQuery.end_at = newVal[1];
      } else {
        this.listQuery.start_at = "";
        this.listQuery.end_at = "";
      }
    },
  },
  created() {
    this.form.match_id = this.listQuery.match_id = this.$route.query.id;
    this.getList();
  },
  methods: {
    handleChange() {},
    handleCheckDetails(row) {
      this.$router.push(
        `/match/checkdetails?match_id=${this.form.match_id}&id=${row.id}`
      );
    },
    // 对征途
    handleMatchChart() {},
    // 设置排序
    handleEditSort(row) {
      // log
      this.num = row.weight
      this.form = row;
      this.rankShow = true;
    },
    handleViewRanking(row) {
      this.$router.push(
        `/match/viewranking?match_system_id=${row.id}&id=${row.match_id}`
      );
    },
    saveData() {
      console.log(this.form);
      if (this.num > 0) {
        this.form.weight = this.num;
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/schoolend/match/systemStore",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.rankShow = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/match/systemList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleEditMatch(row) {
      this.resetForm();
      this.form = Object.assign({}, row);
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    handleCreate() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    resetForm() {
      this.form = {
        id: "",
        match_id: this.form.match_id,
        type: "",
        name: "",
        winner_points: "",
        loser_points: "",
        start_at: "",
        end_at: "",
      };
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.cell {
  padding: 10px 15px;
  display: flex;
  border: 1px solid #4c4c4c;
}
</style>
